enum LangType {
  EN = "en-US",
  ZH = "zh-Hans",
  KO = "ko-KR",
  HK = "zh-Hant-HK",
  JA = "ja",
}

// 支持的语言
const languages = [
  LangType.EN,
  LangType.ZH,
  LangType.KO,
  LangType.HK,
  LangType.JA,
];

// 语言映射
const languagesMap: [RegExp, LangType][] = [
  // hk
  [/^zh-tw/i, LangType.HK],
  [/^zh-hk/i, LangType.HK],
  [/^zh-hant/i, LangType.HK],
  // cn
  [/^zh-hans/i, LangType.ZH],
  [/^zh/i, LangType.ZH],
  // en
  [/^en/i, LangType.EN],
  // ko
  [/^ko/i, LangType.KO],
  // ja
  [/^ja/i, LangType.JA],
];

const isSpaceCN = () => {
  return /switchspace.cn/.test(document.domain);
};

// 默认语言
const defaultLanguage: LangType = isSpaceCN() ? LangType.ZH : LangType.EN;

// 选择语言
const LangSelects: { value: LangType; text: string }[] = [
  {
    value: LangType.EN,
    text: "English",
  },
  {
    value: LangType.ZH,
    text: "简体中文",
  },
  {
    value: LangType.HK,
    text: "繁體中文",
  },
  {
    value: LangType.JA,
    text: "日本語",
  },
  // {
  //   value: LangType.KO,
  //   text: "한국어",
  // },
];

export { LangType, languages, languagesMap, defaultLanguage, LangSelects };
